import './maintenance-requests-list.scss';

import React, { useEffect, useMemo } from 'react';
import {
  Button, Col, Container, ListGroup, Row, Spinner,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { formatDate, splitStringOnUppercase } from '../../../services/format';
import { getDueDate, getStatusLight } from '../../../services/maintenance-helpers';
import { sortByBasic } from '../../../services/table-helpers';
import { selectLoggedInUserPreferences } from '../../../store/slices/auth-slice';
import {
  fetchMaintenanceRequestsForAssetList,
  selectChassisMaintenanceRequestsByAssetId,
  selectGasMaintenanceRequestsByAssetId,
  selectLiquidMaintenanceRequestsByAssetId,
  selectMaintenanceRequestDropdownObjects,
  selectMaintenanceRequestTypes,
} from '../../../store/slices/maintenance-requests-slice';
import { OrangeIcon, RedIcon, YelloIcon } from '../../UI/atoms/CircleFillIcon/circle-fill-icon';

function MaintenanceRequestList({
  handleWorkOrderModalShow,
  isExpanded,
  assetSerialNumber,
  assetTypeId,
  expectedSize,
}) {
  const { datePreference } = useSelector(selectLoggedInUserPreferences);

  const maintenanceRequestTypes = useSelector(
    selectMaintenanceRequestTypes,
  );

  const { maintenanceRequestStatuses } = useSelector(selectMaintenanceRequestDropdownObjects);

  const selectRequestsByAssetId = {
    1: selectGasMaintenanceRequestsByAssetId,
    2: selectLiquidMaintenanceRequestsByAssetId,
    4: selectChassisMaintenanceRequestsByAssetId,
  };

  const requestsByAssetId = useSelector(selectRequestsByAssetId[assetTypeId]);

  // const liquidMaintenanceRequestsByAssetId = useSelector(
  //   selectLiquidMaintenanceRequestsByAssetId,
  // );

  // const gasMaintenanceRequestsByAssetId = useSelector(
  //   selectGasMaintenanceRequestsByAssetId,
  // );

  // const chassisMaintenanceRequestsByAssetId = useSelector(
  //   selectChassisMaintenanceRequestsByAssetId,
  // );

  const maintenanceRequests = requestsByAssetId[assetSerialNumber] || [];

  const requestsList = useMemo(() => {
    return maintenanceRequests.sort(
      (a, b) => sortByBasic(new Date(a.modified), new Date(b.modified)),
    ).reverse()
      .filter((mr) => mr.workOrderId == null || mr.workOrderStatus !== 'Closed');
  }, [JSON.stringify(maintenanceRequests)]);

  const renderStatusLight = (status) => {
    if (status === 'Red') {
      return <RedIcon />;
    }
    if (status === 'Yellow') {
      return <YelloIcon />;
    }
    if (status === 'Orange') {
      return <OrangeIcon />;
    }
    return '';
  };
  const dispatch = useDispatch();

  const fetchMaintenanceRequestsStatus = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestsForAssetListFetch.status,
  );

  useEffect(() => {
    if (isExpanded) {
      setTimeout(() => {
        dispatch(fetchMaintenanceRequestsForAssetList(
          { assetTypeId, assetIds: [assetSerialNumber] },
        ));
      }, 300);
    }
  }, [isExpanded]);

  const requestsAreLoading = fetchMaintenanceRequestsStatus === 'loading';

  const expandedHeight = 60 + (60 * (Math.min(expectedSize, 5)));

  return (
    <div
      style={{
        maxHeight: `${expandedHeight}px`,
        height: `${expandedHeight}px`,
      }}
      className="maintenance-request-list"
    >
      <div
        className="py-2 px-1 mx-2 d-flex flex-column overflow-auto h-100 "
      >
        <h6>Maintenance Requests</h6>
        <div className="flex-grow-1 position-relative overflow-auto border">
          <Spinner
            animation="border"
            variant="primary"
            className={`create-spinner${requestsAreLoading ? ' visible' : ' invisible'}`}
            style={{ display: requestsAreLoading ? undefined : 'none' }}
          />
          <div>
            <ListGroup className="overflow-auto h-100">
              {requestsList
                .map((mr) => (
                  <ListGroup.Item
                    as="li"
                    key={mr.maintenanceRequestId}
                    className="mb-1"
                  >
                    <Container fluid className="me-auto d-flex">
                      <Row className="w-100">
                        <Col sm={2}>
                          <div>
                            <div className="fw-bold">{maintenanceRequestTypes[mr.maintenanceRequestTypeId]?.name}</div>
                            {splitStringOnUppercase(maintenanceRequestStatuses[mr.maintenanceRequestStatusId]?.name ?? '')}
                          </div>
                        </Col>
                        <Col sm={2}>
                          <div>
                            {renderStatusLight(getStatusLight(mr, maintenanceRequestTypes))}
                          </div>
                        </Col>
                        <Col sm={2}>
                          <div title="Due Date">
                            {formatDate(getDueDate(mr, maintenanceRequestTypes), datePreference)}
                          </div>
                        </Col>
                        <Col sm={3}>
                          <div
                            title={mr.details}
                            style={{
                              whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '250px',
                            }}
                          >
                            {mr.details}
                          </div>
                        </Col>
                        <Col sm={3}>
                          <div className="d-flex align-items-left justify-content-end w-100 ml-2">
                            {mr.workOrderId
                              ? (
                                <p>
                                  On Work Order:
                                  {' '}
                                  <NavLink to={`/maintenance/work-orders/${mr.workOrderId}`}>{mr.workOrderId}</NavLink>
                                </p>
                              )
                              : (
                                <Button
                                  className={mr.maintenanceRequestStatusId === 3 ? 'invisible' : ''}
                                  disabled={mr.maintenanceRequestStatusId === 3}
                                  size="sm"
                                  title="Add to Work Order"
                                  onClick={
                                    () => handleWorkOrderModalShow(
                                      mr.maintenanceRequestId,
                                      mr.assetSerialNumber,
                                      mr.assetTypeId,
                                    )
                                  }
                                >
                                  Add to Work Order
                                </Button>
                              )}
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </ListGroup.Item>
                ))}

            </ListGroup>
          </div>
        </div>

      </div>
    </div>
  );
}

export default MaintenanceRequestList;
